<template>
    <Login
      :formFields="formFields"
      :authHeading="$t('admin_login_heading')"
      :cardHeading="$t('admin_login_heading')"
      :loginBtnText="$t('login')"
      type="admin"
    />
  </template>
  
  <script>
  import Login from "@/components/Auth/Login.vue";
  export default {
    components: {
      Login,
    },
    data() {
      return {
        lostPasswordText: "I have lost my password",
        loginBtnText: "Inloggen",
        createAccountText: "Create an account",
        formFields: [
          {
            component: "TextInput",
            name: "email",
            placeholder: this.$t('emailPlaceholder'),
            rules: "email|required",
          },
          {
            component: "TextInput",
            name: "password",
            type: "password",
            placeholder: this.$t('password'),
            rules: "required",
          },
        ],
      };
    },
    computed:{
    }
  };
  </script>
  
  <style lang="scss"></style>
  